$spacing-sm: 4px;
$spacing-md: 8px;
$spacing-lg: 16px;
$border-radius: 4px;
$reviewer-name-width: 120px;
$font-weight-bold: 500;

// Main container for the review history component
.review-history-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  // Review row styles
  .review-row {
    display: flex;
    margin-bottom: $spacing-md;
    align-items: flex-start;

    // Reviewer name styling
    .reviewer-name {
      font-weight: $font-weight-bold;
      margin-right: $spacing-lg;
      width: $reviewer-name-width;
      text-align: right; // Right-align the reviewer name for better visual separation
    }

    // Container for answer boxes
    .answers-container {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-md; // Space between answer boxes
      flex: 1; // Take up remaining space
    }

    // Individual answer box styling
    .answer-box {
      padding: $spacing-sm $spacing-md;
      border-radius: $border-radius;
      // The background-color and color will be applied inline
      display: inline-block;
      margin-bottom: $spacing-sm; // Space between rows if they wrap
    }
  }
}
