.audit-review-split-line {
  width: 1px;
  background-color: #d1d1d1;
  height: 76vh;
}

.paragraph-style {
  line-height: 190%;
}

.weight {
  font-weight: 400;
}

.table-color {
  background-color: red;
}

.highlight {
  background-color: rgba(
    180,
    225,
    255,
    0.5
  ); // Deeper semi-transparent background color
  border: 1px solid rgba(120, 180, 255, 0.8); // Colored border
  box-shadow: 0 0 0 2px rgba(180, 225, 255, 0.5); // Colored box-shadow for highlight
  display: inline; // Display as inline element
  padding: 5px 5px; // Vertical and horizontal padding
  box-decoration-break: clone; // Ensure box-shadow is applied to each line
}

.donut-chart {
  background-color: white;
  border: 1px solid rgba(180, 225, 255, 0.5); // Colored border
}

.icon {
  background-color: #ebebeb;
  border-radius: 5px;
}

.icon:hover {
  background-color: #e5e4e4;
}

.line-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 50px auto;
  background-color: #f0f0f0; /* Optional: to visualize the container */
}

.line {
  position: absolute;
  background-color: black;
}

.horizontal-line {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%); /* Center align vertically */
}

.vertical-line {
  top: 50%; /* Start from the middle horizontally */
  left: 50%;
  width: 2px;
  height: 50%; /* Adjust the height as needed */
  transform: translateX(-50%); /* Center align horizontally */
}

.horizontal-line-below {
  top: calc(50% + 20px); /* Position below the first horizontal line */
  left: 50%; /* Start from the middle */
  width: 50%; /* Adjust the width as needed */
  height: 2px;
  transform: translateY(-50%); /* Center align vertically */
}

.container {
  display: block;
  box-sizing: border-box;
  position: relative; // Add this to contain absolute positioning
  padding: 10px;

  .top-section {
    display: block;
    position: relative;

    .text-content {
      display: inline;
      word-wrap: break-word;
      white-space: pre-wrap;
      padding-right: 80px; // Add space for button, adjust based on button width
    }
    .button {
      position: absolute; // Change from float to absolute
      top: -10px; // Negative padding to reach the top
      right: -10px; // Negative padding to reach the right
      margin: 0; // Remove margins
    }
  }
}
